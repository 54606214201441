import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import { toPlainText } from '../lib/helpers'
import SingleViewHeroBlock from '../components/single-view-hero-block'
import ShowreelSliderBlock from '../components/showreel-slider-block'
import VideoBlock from '../components/video-block'
import PlaylistBlock from '../components/playlist-block'
import PlaylistSliderBlock from '../components/playlist-slider-block'
import SingleViewDescriptionBlock from '../components/single-view-description-block'
import FeaturedProjectsBlock from '../components/featured-projects-block'
import QuoteBlock from '../components/quote-block'
import HighlightBlock from '../components/highlight-block'
import ProjectsWithCreditsBlock from '../components/projects-with-credits-block'
import PDFDownloadBlock from '../components/pdf-download-block'
import RelatedProjectsSliderBlock from '../components/related-projects-slider-block'
import ShortInfoBlock from '../components/short-info-block'

export const query = graphql`
  query ProjectTemplateQuery($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      id
      title
      artist {
        title
        slug {
          current
        }
      }
      category {
        title
      }
      productionCompany
      slug {
        current
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      isIndexed
    }
  }
`

const ProjectTemplate = props => {
  const { data, errors } = props
  const project = data && data.project
  const isIndexed = project && project.isIndexed

  const content = (project._rawContent || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null
      switch (c._type) {
        case 'singleViewHeroBlock':
          const { project, artist } = data
          const title = artist ? artist.title : project.title
          const subtitle = project.productionCompany ? project.productionCompany : null
          el = <SingleViewHeroBlock key={c._key} {...c} title={title} subtitle={subtitle} />
          break
        case 'quoteBlock':
          el = <QuoteBlock key={c._key} {...c} />
          break
        case 'highlightBlock':
          el = <HighlightBlock key={c._key} {...c} />
          break
        case 'singleViewDescriptionBlock':
          el = <SingleViewDescriptionBlock key={c._key} {...c} />
          break
        case 'showreelSliderBlock':
          el = <ShowreelSliderBlock key={c._key} {...c} />
          break
        case 'videoBlock':
          el = <VideoBlock key={c._key} {...c} />
          break
        case 'playlistBlock':
          el = <PlaylistBlock key={c._key} {...c} />
          break
        case 'playlistSliderBlock':
          el = <PlaylistSliderBlock key={c._key} {...c} />
          break
        case 'featuredProjectsBlock':
          el = <FeaturedProjectsBlock key={c._key} {...c} />
          break
        case 'projectsWithCreditsBlock':
          el = <ProjectsWithCreditsBlock key={c._key} artistTitle={artistTitle} {...c} />
          break
        case 'pdfDownloadBlock':
          el = <PDFDownloadBlock key={c._key} {...c} />
          break
        case 'relatedProjectsSliderBlock':
          if (c.items?.length > 0) {
            el = <RelatedProjectsSliderBlock key={c._key} {...c} />
          }
          break
        case 'shortInfoBlock':
          el = <ShortInfoBlock key={c._key} {...c} />
          break
        default:
          el = null
      }
      return el
    })

  return (
    <>
      {errors && <SEO title="GraphQL Error" />}
      {project && (
        <SEO
          title={project.title || 'Untitled'}
          description={toPlainText(project._rawExcerpt)}
          noindex={!isIndexed}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {content}
    </>
  )
}

export default ProjectTemplate
