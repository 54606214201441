import React from 'react'
import { addColon } from '../lib/helpers'

const ShortInfoBlock = ({ items }) => {
  return (
    <ul className="short-info-block">
      {items &&
        items.map(item => (
          <li key={item._key} className="short-info-block__entry">
            <ul className="mob-p-1_2 tab-pl-1 text-caps">
              <li className="short-info-block__entry__title text-xs">{addColon(item.title)}</li>
              <li className="short-info-block__entry__body text-m">{item.body}</li>
            </ul>
          </li>
        ))}
    </ul>
  )
}

export default ShortInfoBlock
